import React from "react";
import { styled } from "linaria/react";
import { Link } from "gatsby";
import Carrot from "./icon-components/Carrot";

const StyledLink = styled.a`
  color: ${props => props.color};
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  cursor: pointer;
  text-decoration: none;
  margin-bottom: 2.5rem;
  width: fit-content;
  svg {
    width: 18px;
    aspect-ratio: 1;
    transition: all 250ms;
    transform: rotate(90deg);
  }
  :hover svg {
    transform: translateX(-0.25rem) rotate(90deg);
  }
`;

const BackLink = ({ path, text, color = "var(--blue-500)" }) => {
  return (
    <StyledLink as={Link} to={path} color={color}>
      <Carrot />
      {text}
    </StyledLink>
  );
};

export default BackLink;
