// @ts-nocheck
import GlobalLayout from "components/page/GlobalLayout";
import PartnersPage from "components/partner-program/updated/PartnersPage";
import React from "react";
import Helmet from "react-helmet";

export default function PartnerProgram() {
  return (
    <GlobalLayout color="var(--gradient-light)">
      <Helmet>
        <title>
          Rhombus Partner Program - Video Surveillance Resellers, Installers &
          Integrators
        </title>
        <meta
          name="description"
          content="Rhombus Partner Program. Discover how IT/video surveillance integrators and resellers can grow their business by offering industry-leading security products."
        />
        <script
          src="https://fast.wistia.com/embed/medias/4mio0nlu86.jsonp"
          async
        />
        <script src="https://fast.wistia.com/assets/external/E-v1.js" async />
      </Helmet>
      <PartnersPage />
    </GlobalLayout>
  );
}
