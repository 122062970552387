import React from "react";
import { css } from "linaria";
import PageContent from "components/page/PageContent";
import SlideInContainer from "components/common/SlideInContainer";
import { StaticImage } from "gatsby-plugin-image";

const sectionClass = css`
  background: var(--nuetral-100);
  color: rgba(43, 46, 48, 0.99);
  padding: 4rem 0px;
  min-height: 450px;
  display: flex;
  align-items: center;

  @media (max-width: 1050px) {
    padding: 4rem 10px;
  }
`;

const contentClass = css`
  display: grid;
  grid-template-columns: 55% 35%;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1050px) {
    grid-template-columns: unset;
    display: block;
    max-width: 445px;
    margin: 0 auto;
  }
`;

const quoteClass = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 2rem;
  width: 100%;
  max-width: 447px;

  @media (max-width: 1050px) {
    margin-top: 35px;
    max-width: 445px;
  }

  div {
    width: 100%;
  }

  q {
    font-style: normal;
    font-size: 1.4rem;
    line-height: 2;

    @media (max-width: 700px) {
      font-size: 18px;
    }
  }

  cite {
    font-style: normal;
    font-size: 16px;
    line-height: 2;

    @media (max-width: 700px) {
      font-size: 14px;
    }
  }

  span {
    font-weight: 900;
  }
`;

const logoClass = css`
  width: 170px !important;
  height: 44px !important;
`;

export default function PartnerVideo() {
  return (
    <section className={sectionClass}>
      <PageContent>
        <div className={contentClass}>
          <div
            className="wistia_responsive_padding"
            style={{ padding: "56.25% 0 0 0", position: "relative" }}
          >
            <div
              className="wistia_responsive_wrapper"
              style={{
                height: "100%",
                left: "0",
                position: "absolute",
                top: "0",
                width: "100%",
              }}
            >
              <span
                className="wistia_embed wistia_async_4mio0nlu86 popover=true popoverAnimateThumbnail=true videoFoam=true"
                style={{
                  display: "inline-block",
                  height: "100%",
                  position: "relative",
                  width: "100%",
                }}
              >
                &nbsp;
              </span>
            </div>
          </div>
          <div className={quoteClass}>
            <StaticImage
              src="./updated/img/eclipse-logo.png"
              alt="eclipse logo"
              className={logoClass}
              objectFit="contain"
            />
            <SlideInContainer>
              <q>
                Since partnering with Rhombus, it&apos;s had a major impact on
                us because we&apos;ve been able to offer a very unique product.{" "}
                <b>
                  As a result of that, we&apos;ve won some major projects,
                  really exciting ones.
                </b>
              </q>
            </SlideInContainer>
            <SlideInContainer>
              <cite>
                <span>Greg Flood</span>
                <br />
                Director of Eclipse Security
              </cite>
            </SlideInContainer>
          </div>
        </div>
      </PageContent>
    </section>
  );
}
