import React from "react";
import { css, cx } from "linaria";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import {
  MainParagraph,
  Title,
  TitleMed,
} from "components/rhombus-UI/theme/typography";
import { GradientButton } from "components/rhombus-UI/theme/buttons";

const sectionContainer = css`
  padding: 4rem 20px;
  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden;
`;

const sectionInner = css`
  max-width: var(--max-width);
  width: 100%;
  display: flex;
  gap: 2rem;
  z-index: 1;
  color: var(--white);
  @media (max-width: 820px) {
    flex-direction: column;
  }
`;

const sectionLeft = css`
  width: 50%;
  flex-grow: 1;
  display: flex;
  align-items: center;
  @media (max-width: 820px) {
    width: 100%;
  }
`;

const sectionRight = cx(
  sectionLeft,
  css`
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  `
);

const backgroundImage = css`
  position: absolute !important;
  left: 0 !important;
  top: 0 !important;
  width: 100% !important;
  height: 100% !important;
  object-position: center !important;
`;

const RhombusBanner = ({
  title = "Blade Title",
  blurb = "Blade Blurb",
  image,
  Button = () => <GradientButton href="">Blade Button</GradientButton>,
}) => {
  return (
    <section className={sectionContainer}>
      <GatsbyImage
        image={getImage(image)}
        alt=""
        objectFit="cover"
        className={backgroundImage}
      />
      <div className={sectionInner}>
        <div className={sectionLeft}>
          <TitleMed style={{ maxWidth: "500px" }}>{title}</TitleMed>
        </div>
        <div className={sectionRight}>
          <MainParagraph>{blurb}</MainParagraph>
          <div></div>
          <Button />
        </div>
      </div>
    </section>
  );
};

export default RhombusBanner;
