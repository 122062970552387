import {
  SectionCol,
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";
import {
  FancyPageTitle,
  MainParagraph,
  PageTitle,
  PreTitle,
} from "components/rhombus-UI/theme/typography";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import useIsMobile from "hooks/useIsMobile";
import { css, cx } from "linaria";
import { styled } from "linaria/react";
import React from "react";

import RhombusButton from "../RhombusButton";
import { toTitleCase } from "../util/helperFunctions";
import BackLink from "../BackLink";

const DeviceHeaderContainer = styled(SectionContainer)`
  background: ${props =>
    props.dark ? "var(--gradient-base)" : "var(--gradient-light)"};
  min-height: ${props => props.minHeight};
  @media (max-width: 600px) {
    min-height: unset;
  }
`;

const DeviceHeaderInner = styled(SectionInner)`
  flex-direction: ${props => (props.reverse ? "row-reverse" : "row")};
  justify-content: space-between;
  @media (max-width: 1000px) {
    flex-direction: column;
  }
  @media (max-width: 600px) {
    gap: ${props => (props.noGap ? "0" : "2.5rem")};
  }
`;

const HeaderLeft = styled(SectionCol)`
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
  a > div {
    max-width: 665px;
  }
  p {
    max-width: 558px;
  }
  h1 {
    max-width: 665px;
  }
  @media (max-width: 1000px) {
    width: 100%;
  }
  @media (max-width: 600px) {
    gap: ${props => (props.noGap ? "0" : "2rem")};
  }
`;

const HeaderRight = styled(SectionCol)`
  position: relative;
  @media (max-width: 1000px) {
    width: 100%;
  }
`;

const buttonContainer = css`
  width: 100%;
  display: flex;
  gap: 1rem;
`;

const imageClass = css`
  max-width: 773px !important;
  max-height: 622px !important;
  width: 100% !important;
  border-radius: 1.25rem;
`;

const imageBottomClass = cx(
  imageClass,
  css`
    bottom: -6rem !important;
    @media (min-width: 1000px) {
      position: absolute !important;
      bottom: -6rem !important;
      right: 0 !important;
      margin: 0 !important;
    }
  `
);
const imageRightClass = cx(
  imageClass,
  css`
    @media (min-width: 900px) {
      width: 966px !important;
      height: 608px !important;
      position: absolute !important;
      right: -70% !important;
      top: 0 !important;
      bottom: 0 !important;
      margin: auto !important;
    }
    @media (min-width: 1300px) {
      right: -9rem !important;
    }
    @media (min-width: 2400px) {
      right: 0 !important;
      width: 100% !important;
    }
  `
);

const imageBottomRightClass = cx(
  imageClass,
  css`
    bottom: -6rem !important;
    right: 0 !important;
    width: 773px !important;
    @media (max-width: 1350px) {
      width: 100% !important;
    }
    @media (min-width: 1000px) {
      position: absolute !important;
      bottom: -6rem !important;
      margin: 0 !important;
    }
  `
);

const HeroLayout1 = ({
  data,
  dark = false,
  reverse = false,
  bottom = false,
  right = false,
  useTitleCase = true,
  backLink = null,
  bgColor = null,
  minHeight = "740px",
  noGap = false,
}) => {
  const { isMobile } = useIsMobile(768);

  const handleImageClass = () => {
    if (bottom && !right) {
      return imageBottomClass;
    }
    if (right && !bottom) {
      return imageRightClass;
    }
    if (bottom && right) {
      return imageBottomRightClass;
    }
    return imageClass;
  };

  const title = useTitleCase ? toTitleCase(data.title) : data.title;

  return (
    <DeviceHeaderContainer
      dark={dark}
      minHeight={minHeight}
      style={bgColor && { background: bgColor }}
    >
      <DeviceHeaderInner reverse={reverse} noGap={noGap}>
        <HeaderLeft noGap={noGap}>
          <TextContainer>
            {backLink && <BackLink path={backLink.path} text={backLink.text} />}
            <PreTitle color={dark ? "var(--white)" : "unset"}>
              {data.preTitle}
            </PreTitle>
            {dark ? (
              <PageTitle color={dark ? "var(--white)" : "unset"}>
                {isMobile && data.mobileTitle ? data.mobileTitle : title}
              </PageTitle>
            ) : (
              <FancyPageTitle>
                {isMobile && data.mobileTitle ? data.mobileTitle : title}
              </FancyPageTitle>
            )}
            <MainParagraph color={dark ? "var(--white)" : "unset"}>
              {data.description}
            </MainParagraph>
          </TextContainer>
          <div className={buttonContainer}>
            {data.button1 && (
              <RhombusButton
                title={data.button1.text}
                path={data.button1.path}
                useHref={data.button1.useHref}
                target={data.button1.target}
              />
            )}
            {data.button2 && (
              <RhombusButton
                title={data.button2.text}
                path={data.button2.path}
                type="secondary"
                useHref={data.button2.useHref}
                target={data.button2.target}
              />
            )}
          </div>
        </HeaderLeft>
        <HeaderRight>
          <GatsbyImage
            image={getImage(data.image)}
            alt={data.title}
            className={handleImageClass()}
            objectFit="contain"
          />
        </HeaderRight>
      </DeviceHeaderInner>
    </DeviceHeaderContainer>
  );
};

export default HeroLayout1;
